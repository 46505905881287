<mat-sidenav-container fullscreen class="profilepage">
    <mat-sidenav-content>
        <app-header></app-header>
      	<div class="main-content topinfo mt-3">
          <div class="container">
            <div class="row">
                <div class="col-3">
                   <span>Limit</span><br>
                   <span>{{userDetails?.details?.limit}}</span>
                </div>
                <div class="col-6 text-center">
                    <span>Profile</span><br>
                    <span>Icon</span><br>
                    <span>{{userDetails?.details?.username}}</span><br>
                    <span>Exposure</span><br>
                    <span>{{userDetails?.details?.exposure}}</span>
                </div>
                <div class="col-3">
                    <span>Balance</span><br>
                    <span>{{userDetails?.details?.balance}}</span>
                </div>
                <div class="col-2"></div>
                <div class="col-8 mt-2 p-1 bg-theme text-center rounded"><span class="text-white">BET HISTORY</span></div>
                <div class="col-2"></div>
            </div>
            
          </div>
          <!-- card -->
        
          <div class="container" style="overflow:scroll;min-height:67vh">
            <ng-container *ngIf="betList?.length>0">
           <mat-card *ngFor="let x of betList" class="example-card border border-primary mt-3">
            <mat-card-subtitle class="row"><span class="subtitletext col-12 text-center"><span>Img</span>&nbsp;&nbsp;{{x?.placedTime|date:'medium'}}
                <!-- <span class="col-4">-250 &#8377;</span> -->
                <ng-container *ngIf="x?.runnerId == 1">
                    <span *ngIf="((x?.result=='WON') && (x?.type=='Back'))" class="float-right text-success font-weight-bold">+{{(x?.rate*x?.stake) | number:'.0-2'}}</span>
                    <span *ngIf="((x?.result=='WON') && (x?.type=='Lay'))" class=" float-right text-success font-weight-bold">+{{x?.stake | number:'.0-2'}}</span>
                    <span *ngIf="((x?.result=='LOST') && (x?.type=='Back'))" class=" float-right text-danger font-weight-bold">-{{x?.stake | number:'.0-2'}}</span>
                    <span *ngIf="((x?.result=='LOST') && (x?.type=='Lay'))" class=" float-right text-danger font-weight-bold">-{{x?.rate*x?.stake | number:'.0-2'}}</span>
                </ng-container>
                <ng-container *ngIf="x?.runnerId != 1">
                    <span *ngIf="((x?.result=='WON') && (x?.type=='Back'))" class=" float-right text-success font-weight-bold">+{{(x?.rate-1)*x?.stake | number:'.0-2'}}</span>
                    <span *ngIf="((x?.result=='WON') && (x?.type=='Lay'))" class=" float-right text-success font-weight-bold">+{{x?.stake | number:'.0-2'}}</span>
                    <span *ngIf="((x?.result=='LOST') && (x?.type=='Back'))" class="float-right text-danger font-weight-bold">-{{x?.stake | number:'.0-2'}}</span>
                    <span *ngIf="((x?.result=='LOST') && (x?.type=='Lay'))" class="c float-right text-danger font-weight-bold">-{{(x?.rate-1)*x?.stake | number:'.0-2'}}</span>
                </ng-container>
            </span>
            </mat-card-subtitle>
            <mat-card-content class="row">
                  <p class="col-12 text-left text-success">{{x?.marketName}}</p>
                  <p class="col-12 text-left text-success">{{x?.eventName}}</p>
                  <p class="col-12 text-left text-success">{{x?.marketId}}</p>
                  <hr class="bg-defualt">
                  <div class="row mrketlist">
                  <span class="col-5">Market</span><span class="col-7 float-right">{{x?.marketName}}</span>  </div>
                  <div class="row mrketlist">  <span class="col-5">Selection</span><span class="col-7 float-right">{{x?.selectionName}}</span>  </div>
                  <div class="row mrketlist"> <span class="col-5">Rate</span><span class="col-7 float-right text-success">{{x?.type}}@{{x?.rate}}</span>  </div>
                  <div class="row mrketlist"> <span class="col-5">Stake</span><span class="col-7 float-right text-success">{{x?.stake|number:'.0-2'}}</span>  </div>
                  <div class="row mrketlist"><span class="col-5">Time</span><span class="col-7 float-right">{{x?.placedTime|date:'medium'}}</span>      </div>
            </mat-card-content>
          </mat-card> 
       </ng-container>
       <div class="font-weight-bold" *ngIf="betList?.length==0" style="position:absolute;top:50%;right:28%;">
         <span>There is no data to display.</span>
       </div>
          
          </div>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
