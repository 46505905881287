<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
      
        <app-header></app-header>
      	<div class="main-content container mt-3">
            <div class="row">
                      <div class="col-6 text-center">
                        <h4 class="mt-2 font-weight-bold" style="color:#fff;">
                          Total : <span *ngIf="total_ref">{{-1*total_ref[0]?.total}}</span></h4>
                      </div>
                     

                     
                      <div class="balanceShow col-12" style=" color: #fff;">
                        
                      </div>
                      </div>
                      <div class="row"> 
                    <div class="table-div-content-show col-12" style="margin-top:20px;max-height:47vh;overflow:scroll;">
                      <table class="table" style="text-align:center;" >
                        <thead style="background-color:#3c444b ;color:#fff; font-size: 1rem;" >
                          <tr>
                            <th scope="col">Sr No</th>
                            <th scope="col">Time</th>
                            <th scope="col">Username</th>
                            <th scope="col">Event Name</th>
                            <th scope="col">Market Name</th>
                            <th scope="col">Profit|Loss</th>
                            
                           
                          </tr>
                        </thead>
                        <tbody class="t-body">
                          <tr class="tr-body" *ngFor="let r of ref_history;let i=index;">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                              {{r?.time|date:'medium'}}
                          </td>
                          <td>
                            {{r?.username}}
                          </td>
                            <td>
                              {{r?.eventName}}
                            </td>
                            <td>
                              {{r?.marketName}}
                            </td>
                            <td>
                              {{-1*r?.settle_amount}}
                            </td>
                           
                          </tr>
                        </tbody>
                      </table>
                  
                    </div>
                  
                <!-- end -->
              </div>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
